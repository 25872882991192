import { getStoredBattlefieldUserToken } from 'utils';
import { get, post } from 'utils/fetch';

import { types } from '..';
import { del } from '../../utils/fetch';
import { Martian } from '../reducers/lands';

// token from email, save it to session storage
export const getBattleProfile = async () => {
  if (!getStoredBattlefieldUserToken()) {
    return { type: null, payload: {} }
  }

  const response = await get('/v1/battle/me', {}, getStoredBattlefieldUserToken())
  
  // @TODO handle error response
  console.log(response);
  if (!response.result.me) {
    throw new Error('Could not fetch profile')
  }

  return {
    type: types.battlefield.SET_BATTLE_PROFILE,
    payload: {
      nickname: response.result.me.nickname,
      has_martians_in_battlefield: response.result.me.has_martians_in_battlefield,
      step: response.result.me.step,
      fees_balance: response.result.me.fees_balance
    }
  }
}

export const getMartiansInTrenches = async () => {
  const response = await get('/v1/battle/trenches/martians', {}, getStoredBattlefieldUserToken())

  // @TODO handle error response

  return {
    type: types.battlefield.SET_MARTIANS_IN_TRENCHES,
    payload: {
      martians: response.result.martians,
    }
  }
}
export const getMartiansInField = async () => {
  const response = await get('/v1/battle/field/martians', {}, getStoredBattlefieldUserToken())

  return {
    type: types.battlefield.SET_MARTIANS_IN_BATTLEFIELD,
    payload: {
      martians: response.result.martians
    }
  }
}

export const getBattleHistory = async () => {
  const response = await get('/v1/battle/history', {}, getStoredBattlefieldUserToken())

  // @TODO handle error response
  if (!response.result.history) {
    throw new Error('Could not fetch history')
  }

  return {
    type: types.battlefield.SET_BATTLE_HISTORY,
    payload: {
      history: response.result.history
    }
  }
}

// export const sendMartiansToBattlefield = async (address: string, selectedMartians: Array<number>) => {
//   await post('/v1/admin/battle/field/add', { address, martian_ids: selectedMartians });

//   return {
//     type: types.UPDATE_USER,
//     payload: {
//       nextStep: BattleStep.send_martians_to_battlefield
//     }
//   }
// }


export const withdrawMartianFromBattlefield = async (martian: Martian, onFeesBalanceNeeded) => {
  // @TODO
  const response = await post('/v1/battle/withdraw/' + martian.id, {}, getStoredBattlefieldUserToken());

  // @TODO depending on response, dispatch action
  // either withdraw went OK either user needs to deposit fees
  if (response.status == 'fail' && response.result.balance_needed) {
    onFeesBalanceNeeded(String(response.result.balance_needed));
    throw new Error(response.result.error);
  }

  if (response.status == 'fail') {
    throw new Error(response.result.error);
  }

  return {
    type: types.battlefield.MARTIAN_WITHDRAWN,
    payload: {
      martian: martian
    }
  }
}

export const registerForRandomBattle = async (martianId: number) => {
  const response = await post('/v1/battle/match/random/' + martianId, {}, getStoredBattlefieldUserToken());

  if (response.status == 'fail') {
    // @TODO check that it's a wait result and not a "real" error
    return {
      type: types.battlefield.REGISTER_RANDOM_BATTLE_WAIT,
      payload: {
        martian_id: martianId
      }
    }
  }

  if (!response.result.battle) {
    throw new Error("Unexpected error");
  }

  return {
    type: types.battlefield.REGISTER_RANDOM_BATTLE_FOUND,
    payload: {
      battle: {
        id: response.result.battle.id,
        gamers: response.result.battle.gamers,
        winner_id: response.result.battle.winner_id,
        loser_id: response.result.battle.loser_id,
        started_at: response.result.battle.started_at,
        ended_at: response.result.battle.ended_at
      }
    }
  }
}

export const cancelRandomBattle = async (martianId: number) => {
  const response = await del('/v1/battle/match/random/' + martianId, getStoredBattlefieldUserToken()); // TODO

  if (response.status == 'fail') {
    throw new Error(response.result.error);
  }

  return {
    type: types.battlefield.REGISTER_RANDOM_BATTLE_CANCEL,
    payload: {},
  }
}


export const fetchRandomBattleWaitResult = async (martianId: number | null) => {
  if (!martianId) {
    return {}; 
  }
  
  const response = await get('/v1/battle/match/wait/' + martianId, {}, getStoredBattlefieldUserToken());

  if (response.status === 'fail' && response.status_code === 0) {
    return { type: null, payload: {} }
  }

  if (response.status == 'fail' || !response.result.battle) {
    // @TODO check that it's a wait result and not a "real" error
    // throw new Error(response.result.error);
  }

  if (response.status == 'ok' && response.result.wait) {
    return {
      type: types.battlefield.REGISTER_RANDOM_BATTLE_WAIT,
      payload: {
        martian_id: martianId
      }
    }
  }

  return {
    type: types.battlefield.REGISTER_RANDOM_BATTLE_FOUND,
    payload: {
      battle: {
        id: response.result.battle.id,
        gamers: response.result.battle.gamers,
        winner_id: response.result.battle.winner_id,
        loser_id: response.result.battle.loser_id,
        started_at: response.result.battle.started_at,
        ended_at: response.result.battle.ended_at
      }
    }
  }
}

export const getBattle = async (battleId: number) => {
  const response = await get('/v1/battle/match/current/' + battleId, {}, getStoredBattlefieldUserToken());

  if (response.status == 'fail' || !response.result.battle) {
    throw new Error(response.result.error);
  }

  return {
    type: types.battlefield.SET_CURRENT_BATTLE,
    payload: {
      battle: {
        id: response.result.battle.id,
        gamers: response.result.battle.gamers,
        winner_id: response.result.battle.winner_id,
        loser_id: response.result.battle.loser_id,
        started_at: response.result.battle.started_at,
        ended_at: response.result.battle.ended_at
      }
    }
  }
}

export const sendChallenge = async (martianId: number, username: string, isFrendly: boolean) => {
  const response = await post('/v1/battle/match/challenge', {
    id: martianId,
    username: username,
    friendly: isFrendly ? 1 : 0
  }, getStoredBattlefieldUserToken());

  if (response.status == 'fail') {
    throw new Error(response.result.error);
  }

  return {
    type: types.battlefield.CHALLENGE_SENT,
    payload: {}
  }
}

export const acceptChallenge = async (martianId: number, onBattleConfirmed) => {
  const response = await post('/v1/battle/match/challenge/accept', {
    id: martianId,
  }, getStoredBattlefieldUserToken());

  if (response.status == 'fail' || !response.result.battle) {
    throw new Error(response.result.error);
  }

  onBattleConfirmed(response.result.battle.id);

  return {
    type: types.battlefield.SET_CURRENT_BATTLE,
    payload: {
      battle: {
        id: response.result.battle.id,
        gamers: response.result.battle.gamers,
        winner_id: response.result.battle.winner_id,
        loser_id: response.result.battle.loser_id,
        started_at: response.result.battle.started_at,
        ended_at: response.result.battle.ended_at
      }
    }
  }
}

export const notifyTopupTransaction = async (transactionHash) => {
  const response = await get(
    '/topup', 
    {
      txHash: transactionHash
    },
    undefined,
    { isNodeJSQuery: true } // Call different backend endpoint
  );

  if (!response.status || response.status !== 'ok') {
    throw new Error('Could not notify topup');
  }

  return {
    type: null,
    payload: {}
  }
}