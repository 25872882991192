import { types } from '..';
import { Martian } from './lands';

export type BattlefieldType = {
	history: any;
	nickname: string | null;
	hasMartiansInBattlefield: boolean;
	martiansInTrenches: Martian[];
	martians: Martian[];
	feesBalance: number;
	isBeingMatchedWithPlayer: boolean;
	martianIdBeingMatched: number | null;
	currentBattle: BattleType | null;
};

export type GamerCryptoType = {
	symbol: string;
	start_fx: string;
	current_fx: string;
	end_fx: string;
};

export type GamerType = {
	id: number;
	username: string;
	address: string;
	martian: Martian;
	crypto: GamerCryptoType;
}

export type BattleType = {
	id: number;
	gamers: GamerType[];
	winner_id: number | null;
	loser_id: number | null;
	started_at: number | null;
	ended_at: number | null;
}

// initial state
export const battlefieldState: BattlefieldType = {
	history: [],
	martiansInTrenches: [],
	martians: [],
	nickname: null,
	hasMartiansInBattlefield: false,
	feesBalance: 0.0,
	isBeingMatchedWithPlayer: false,
	martianIdBeingMatched: null,
	currentBattle: null
};

export const battlefieldReducer = (
	state: BattlefieldType,
	{type, payload}: { type: string; payload: any }
) => {
	switch (type) {
		// TODO add action cases
		case types.battlefield.SET_MARTIANS_IN_TRENCHES: {
			return {
				...state,
				martiansInTrenches: payload.martians
			}
		}
		case types.battlefield.SET_MARTIANS_IN_BATTLEFIELD: {
			return {
				...state,
				martians: payload.martians
			}
		}
		case types.battlefield.SET_BATTLE_HISTORY: {
			return {
				...state,
				history: payload.history
			}
		}
		case types.battlefield.SET_BATTLE_PROFILE: {
			return {
				...state,
        nickname: payload.nickname,
        hasMartiansInBattlefield: payload.has_martians_in_battlefield,
        feesBalance: payload.fees_balance,
				step: payload.step
			};
		}
		case types.battlefield.SET_CURRENT_BATTLE: {
			return {
				...state,
				currentBattle: payload.battle
			}
		}
		case types.battlefield.REGISTER_RANDOM_BATTLE_WAIT: {
			return {
				...state,
				isBeingMatchedWithPlayer: true,
				martianIdBeingMatched: payload.martian_id
			}
		}
		case types.battlefield.REGISTER_RANDOM_BATTLE_FOUND: {
			// TODO put this somewhere else
			// localStorage.setItem('battlefield_current_battle_id', payload.battle.id);

			return {
				...state,
				isBeingMatchedWithPlayer: false,
				currentBattle: payload.battle,
				martianIdBeingMatched: payload.martian_id
			}
		}
		case types.battlefield.REGISTER_RANDOM_BATTLE_CANCEL: {
			return {
				...state,
				isBeingMatchedWithPlayer: false,
				martianIdBeingMatched: null
			}
		}
		case types.battlefield.MARTIAN_WITHDRAWN: {
			return {
				...state,
				martiansInTrenches: [ ...state.martiansInTrenches, payload.martian ],
				martians: state.martians.filter(m => m.id !== payload.martian.id),
			}
		}
		default:
			return state;
	}
};

export default battlefieldReducer;
