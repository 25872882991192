import { BattleStepType, types } from 'state';

export enum SignupStep {
  get_info = "GET_INFO",
  set_username = "SET_USERNAME",
  set_email = "SET_EMAIL",
  wait_email_confirmation = "WAIT_EMAIL_CONFIRMATION",
  email_confirmed = "EMAIL_CONFIRMED"
}

export enum AddMartianStep {
  add_martians = "ADD_MARTIAN",
  mint_martians = "MINT_MARTIANS"
}

export enum BattleStep {
  add_martian = 'ADD_MARTIAN',
  battle_history = 'BATTLE_HISTORY',
  withdraw_martians = 'WITHDRAW_MARTIANS',
  send_martians_to_battlefield = 'SEND_MARTIANS_TO_BATTLEFIELD',
  start_match = 'START_MATCH',
  search_match = 'SEARCH_MATCH',
  match_in_progress = 'MATCH_IN_PROGRESS',
  see_match_status = 'SEE_MATCH_STATUS',
  match_victory = 'MATCH_VICTORY',
  match_defeat = 'MATCH_DEFEAT'
}


export type UserType = {
  nickname: string;
  email: string;
  isVerified: boolean;
  step: BattleStepType | undefined;
  address: string | undefined;
  background: string;
  nextStep: SignupStep | AddMartianStep | undefined;
  token: string;
}

export const userState: UserType = {
  nickname: '',
  email: '',
  isVerified: false,
  step: undefined,
  address: undefined, // eth address, must be unlocked
  background: '2',
  nextStep: SignupStep.get_info, // initial state
  token: '',
}

export const userReducer = (state: UserType, action: { type: string, payload: any }) => {
  switch(action.type) {
    case types.UPDATE_USER: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: return { ...state }
  }
}

export default userReducer;