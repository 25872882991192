import { useState, useEffect } from "react";

function getSize() {
	return {
		height: window.innerHeight,
		width: window.innerWidth,
	};
}

function useWindowSize() {
	const [windowSize, setWindowSize] = useState({
		height: 0,
		width: 0,
	});

	function handleResize() {
		setWindowSize(getSize());
	}

	useEffect(() => {
		setWindowSize(getSize());

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return windowSize;
}
export default useWindowSize;
