import { forwardRef } from "react";

function Button(
	{
		children,
		className = "",
		as = "button",
		small = false,
		ghost = false,
		...props
	}: any,
	ref
) {
	const Component = as;
	const padding = small ? "px-4 py-2" : "px-6 py-2";
	const spacing = "tracking-wide";
	const fontSize = small ? "text-base" : "text-xl";
	const bg = ghost
		? "border-2 border-orange-500 text-orange-500 hover:text-orange-600 hover:border-orange-600"
		: "border border-transparent font-bold text-blueGray-50 hover:bg-opacity-20 hover:from-orange-500 hover:to-orange-400";
	const commons =
		"pointer-events-auto inline-flex justify-center m-auto whitespace-nowrap items-center rounded-full disabled:cursor-not-allowed disabled:opacity-60".split(
			" "
		);
	const classes = [
		...new Set(
			commons
				.concat((bg || "").split(" "))
				.concat((className || "").split(" "))
				.concat((padding || "").split(" "))
				.concat((spacing || "").split(" "))
				.concat((fontSize || "").split(" "))
		),
	].join(" ");

	return (
		<Component ref={ref} className={classes} {...props}>
			{children}
			<style jsx>
				{`
					${!ghost
						? `
						background-image: linear-gradient(87.65deg, #D44717 3.28%, #EF5A15 44.99%, #FE8502 90.95%);
						box-shadow: 0px 4px 26px rgba(226, 81, 22, 0.7);
					`
						: ""}
				`}
			</style>
		</Component>
	);
}

export default forwardRef(Button);
