import qS from "qs";
import isPlainObject from "lodash/isPlainObject";

const signals = {};

export default async function customFetch(
	endpoint: string,
	params: anyObj,
	token?: string,
	options?: {
		withoutHeaders?: boolean;
		isCompany?: boolean;
		contentType?: string;
		isNodeJSQuery?: boolean;
	}
) {	
	let tok = token || (options?.isNodeJSQuery ? process.env.NEXT_PUBLIC_NODEJS_API_TOKEN : process.env.NEXT_PUBLIC_TOKEN);
	try {
		const prop = endpoint + JSON.stringify(params || {});
		if (signals[prop]) {
			signals[prop].abort();
		}
		signals[prop] = new AbortController();
		params.signal = signals[prop]?.signal;
	} catch (e) {}

	if (!options?.withoutHeaders && !/http/.test(endpoint)) {
		params.headers = {
			"content-type": options?.contentType || "application/json",
			Authorization: tok ? `Bearer ${tok}` : "",
		};
	}
	if (!/http/.test(endpoint)) {
		params.headers = {
			...(params.headers || {}),
			Authorization: tok ? `Bearer ${tok}` : "",
		};
		endpoint = options?.isNodeJSQuery ? process.env["NEXT_PUBLIC_NODEJS_API"] + endpoint : process.env["NEXT_PUBLIC_API"] + endpoint;
	}

	
	return fetch(endpoint, params)
		.then((res) => handleResponse(res, endpoint))
		.catch((e) => handleError(e, endpoint));
}

export const get = (
	endpoint: string,
	params = {},
	token?: string,
	options?: object
) => {
	let url = endpoint;
	if (Object.keys(params).length) {
		url += `?${qS.stringify(params, { arrayFormat: "brackets" })}`;
	}
	return customFetch(
		url,
		{
			method: "GET",
		},
		token,
		options
	);
};
export const post = (
	endpoint: string,
	params?: anyObj,
	token?: string,
	options?: object
) =>
	customFetch(
		endpoint,
		{
			body: params ? JSON.stringify(params) : undefined,
			method: "POST",
		},
		token,
		options
	);

export const put = (
	endpoint: string,
	params = {},
	token?: string,
	options?: object
) =>
	customFetch(
		endpoint,
		{
			body: JSON.stringify(params),
			method: "PUT",
		},
		token,
		options
	);

export const del = (
	endpoint: string,
	token?: string,
	options?: object,
	params?: object
) =>
	customFetch(
		endpoint,
		{
			method: "DELETE",
			body: params ? JSON.stringify(params) : undefined,
		},
		token,
		options
	);

export async function handleResponse(res: any, endpoint) {
	const isOk = /2../.test(res.status);
	try {
		const json = await res.json();
		let result = json.result || json || {};
		if (!isPlainObject(result)) {
			result = {
				value: json.result || {},
			};
		}
		if (/fail/.test(json.status)) {
			const error = json.error || result.error || "error.generic";
			return {
				result: { ...result, error },
				status: "fail",
				status_code: res.status,
			};
		} else {
			const returnValue = {
				status: "ok",
				status_code: res.status,
				result: { ...result },
			};
			delete returnValue.result.status;
			return returnValue;
		}
	} catch (e) {
		const result = {
			status: isOk ? "ok" : "fail",
			status_code: res.status,
			result: {
				error: isOk ? undefined : res.error || "error.network",
			},
		};
		return result;
	}
}

export function handleError(error: any, endpoint: string) {
	const { message } = error;
	return {
		status: "fail",
		result: { error },
		status_code: 0,
		message: message || "error.network",
	};
}
