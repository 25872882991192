import { useEffect, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { types, useGlobalState } from 'state';
import { getMartianById } from 'state/actions/lands';
import { log, LOG_ACTIONS } from 'utils';
import { BALANCE_OF, GET_MARTIAN_ID, HISTORY, MARTIAN, MINT, PRICES, REDEEM, REDEEM_INFO } from 'utils/endpoints';
import { get, post } from 'utils/fetch';

const infuraId = process.env.NEXT_PUBLIC_INFURIA_ID;
const escrowEthAddress =
	process.env.NEXT_PUBLIC_ESCROW_ETH_ADDRESS ||
	"0x435998ae3Ee2D04042242A1dad4F125D1eF89b90";

const gasFees = {
	withdrawBidForMartian: 90000,
	enterBidForMartian: 186000,
	martianNoLongerForSale: 55000,
	withdraw: 55000,
	buyMartian: 420000,
	acceptBidForMartian: 300000,
	offerMartianForSale: 180000,
	mint: 425000,
	redeem: [320000, 520000, 720000, 920000, 1150000],
	addMartianToBattlefield: 150000, // ???
	topupBattlefieldWithdrawBalance: 0, // ???
};

export default function useWeb3({
	tokenId,
	readOnly,
}: {
	tokenId?: number;
	readOnly?: boolean;
}) {
	const libs = useRef<any>(null);
	const { addToast, removeToast } = useToasts();
	const [{ web3 }, dispatch] = useGlobalState();
	const { claim, contracts, accounts, web3js } = web3 || {};

	useEffect(() => {
		(async () => {
			Promise.all([
				await import("web3"),
				await import("web3modal"),
				await import("@walletconnect/web3-provider"),
				await import("walletlink"),
			]).then((res) => {
				const [Web3, Web3Modal, WalletConnectProvider, wl] = res;
				if (libs) {
					libs.current = {
						Web3: Web3.default,
						Web3Modal: Web3Modal.default,
						WalletConnectProvider: WalletConnectProvider.default,
						WalletLink: wl.default,
					};
				}
			});
		})();
	}, [libs]);

	useEffect(() => {
		(async function load() {
			if (!libs?.current) {
				setTimeout(load, 300);
			}
			if (!readOnly) {
				const { web3js } = await loadModal(false);
				if (web3js) {
					loadAbis(web3js);
				}
			}
		})();
	}, [readOnly, libs]);

	// useEffect(() => {
	// 	if (readOnly) {
	// 		return;
	// 	}
	// 	getTotalSupply();
	// 	let int = setInterval(getTotalSupply, 10000);
	// 	return () => {
	// 		clearInterval(int);
	// 	};
	// }, [readOnly, contracts.base?._address, accounts.current]);

	const privateMethods = {
		lockedEth: async function _lockedEth() {
			try {
				const total = await contracts.auction?.methods
					?.addressToPendingWithdrawal?.(accounts.current)
					.call();
				dispatch({
					type: types.web3.UPDATE_LOCKED_ETH,
					payload: +total ? web3js.utils.fromWei(total, "ether") : 0,
				});
			} catch (e) {}
		},
		getAccountHistory: async function _getAccountHistory(address) {
			try {
				const { result } = await get(HISTORY.replace(":id", address));
				const history = [...(result.history || [])];
				dispatch({
					type: types.web3.UPDATE_ACCOUNT_HISTORY,
					payload: {
						id: address,
						value: history,
					},
				});
			} catch (e) {}
		},
		balanceOf: async function _balanceOf(account?: string | number) {
			if (!account) {
				return;
			}
			try {
				const { result } = await get(BALANCE_OF.replace(":id", account + ""));
				if (!result.error) {
					dispatch({
						type: types.web3.SET_ACCOUNT_BALANCE,
						payload: {
							id: account,
							value: {
								total: result.martians.length,
								lands: result.martians,
								ref: result.ref,
							},
						},
					});
				}
			} catch (e) {
				console.log(e.message);
			}
		},
		updateLand: async function _updateLand(id?: number) {
			const card = id || tokenId;
			if (typeof card !== "number") {
				return;
			}
			const { result } = await getMartianByTokenId(card);
			if (!result.error && result.martian?.id) {
				getMartianById(result.martian.id).then(dispatch);
			}
		},
		update: function _update(tokenId?: number) {
			if (
				accounts.current &&
				contracts.base?._address &&
				contracts.auction?._address
			) {
				privateMethods.lockedEth();
				privateMethods.balanceOf(accounts.current);
				privateMethods.getAccountHistory(accounts.current);
			}
			privateMethods.updateLand(tokenId);
		},
	};

	const writeAction = {
		redeemMartian: async (): Promise<any> => {
			let a = accounts?.current;
			let c = contracts?.base;
			let w = web3js;
			if (!(a && c && w)) {
				const { web3js, accounts, error } = await loadModal(true);
				if (error) {
					return { result: { error } };
				}
				const { contracts } = await loadAbis(web3js);
				a = accounts?.current;
				c = contracts?.base;
				w = web3js;
			}
			try {
				return await get(REDEEM_INFO, {
					address: a,
				});
			} catch (e) {}
		},
		claimRandomMartians: async ({
			on,
			onError,
			onConfirmation,
			ids,
			nbMint,
		}: any): Promise<any> => {
			let a = accounts?.current;
			let c = contracts?.base;
			let w = web3js;
			log(LOG_ACTIONS.MARTIANS_MINT_CLICKED);
			if (!(a && c && w)) {
				log(LOG_ACTIONS.MARTIANS_ASK_ACCOUNT);
				const { web3js, accounts, error } = await loadModal(true);
				if (error) {
					onError("");
					return;
				}
				const { contracts } = await loadAbis(web3js);
				a = accounts?.current;
				c = contracts?.base;
				w = web3js;
			}
			try {
				log(LOG_ACTIONS.MARTIANS_MINT_STARTED);
				const rf = localStorage.getItem("rf");
				const params = { address: a };
				if (ids?.length) {
					params["parcel_ids"] = ids;
				} else {
					params["nb_mint"] = nbMint || 1;
				}
				if (rf) {
					params["rf"] = rf;
				}

				const { result } = await post(ids?.length ? REDEEM : MINT, {
					...params,
				});

				if (result.error) {
					log(LOG_ACTIONS.MARTIANS_MINT_ERROR, undefined, undefined, {
						mint_error: result.error,
					});
					if (typeof result.error === "string") {
						onError(result.error);
					}
					return;
				}
				const { martians, with_parcels } = result;
				const signatures: string[] = [];
				const martianIds: number[] = [];
				martians?.forEach(({ block, signature, id, meta }) => {
					martianIds.push(id || Math.sqrt(block - 9999) - 69);
					signatures.push(meta?.signature || signature);
				});

				// const gasPrice = await getGasPrice();
				const options = {
					from: a,
					value: w?.utils.toWei(
						(ids?.length ? 0 : 0.08) * martians.length + "",
						"ether"
					),
					gasLimit: gasFees.redeem[martians.length - 1],
				};
				// if (gasPrice && w?.utils) {
				// 	options["gasPrice"] = w?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				if (!(signatures?.length && martianIds?.length)) {
					onError("We didn't find any available Martian :(");
					return;
				}

				return c?.methods
					?.mintMartians(
						signatures,
						martianIds,
						with_parcels?.map(({ meta }) => meta.token_id) || [],
						a
					)
					.send(options)
					.on("transactionHash", on)
					.on("error", (err) => {
						log(LOG_ACTIONS.MARTIANS_MINT_ERROR, undefined, undefined, {
							mint_error: err?.message || JSON.stringify(err),
							owner_address: a,
						});
						onError(err);
					})
					.then(async (res) => {
						const minted = {};
						if (martians.length > 1) {
							res?.events?.Discovery?.forEach(({ returnValues }) => {
								minted[returnValues[2]] = returnValues[1];
							});
						} else {
							const val = res?.events?.Discovery.returnValues;
							minted[val[2]] = val[1];
						}
						log(LOG_ACTIONS.MARTIANS_MINT_SUCCESS);
						const m = await Promise.all(
							martianIds.map(async (id) => {
								const { result } = await get(MARTIAN.replace(":id", id + ""));
								const { assets, ...rest } = result.martian;
								return {
									...rest,
									assets: {
										...assets,
										opensea_url: `https://opensea.io/assets/0x9f22b58A19B7Fa667C6723d558399617729f0C49/${
											minted[rest.id]
										}`,
									},
								};
							})
						);
						onConfirmation(m);
					});
			} catch (e) {
				log(LOG_ACTIONS.MARTIANS_MINT_ERROR, undefined, undefined, {
					mint_error: e?.message,
				});
				onError(e);
				catchError(null, e);
			}
		},
		offerMartianForSale: async ({
			tokenId,
			price,
			on,
			onError,
			onConfirmation,
		}): Promise<any> => {
			try {
				// const gasPrice = await getGasPrice();
				const options = {
					from: accounts.current,
					gasLimit: gasFees.offerMartianForSale,
				};
				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				await contracts.base?.methods
					?.offerMartianForSale(tokenId, price)
					.send(options)
					.on("transactionHash", on)
					.on("error", (e) => {
						onError(e);
						catchError(tokenId, e);
					})
					.then((receipt) => {
						onConfirmation(receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				catchError(tokenId, e);
			}
		},
		acceptBidForMartian: async (
			tokenId: number,
			bid: { eth: number; usd: string }
		) => {
			try {
				emittingEvent(tokenId);
				// const gasPrice = await getGasPrice();
				const options = {
					from: accounts.current,
					gasLimit: gasFees.acceptBidForMartian,
				};
				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				await contracts.base?.methods
					?.acceptBidForMartian(
						tokenId,
						web3js.utils.toWei((bid.eth || 0) + "", "ether")
					)
					.send(options)
					.on("transactionHash", () => onTransactionHash(tokenId))
					.on("error", (e) => catchError(tokenId, e))
					.then((receipt) => {
						parseResult(tokenId, receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				catchError(tokenId, e);
			}
		},
		martianNoLongerForSale: async (tokenId: number) => {
			try {
				emittingEvent(tokenId);
				// const gasPrice = await getGasPrice();
				const options = {
					from: accounts.current,
					gasLimit: gasFees.martianNoLongerForSale,
				};
				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				await contracts.auction?.methods
					?.martianNoLongerForSale(tokenId)
					.send(options)
					.on("transactionHash", () => onTransactionHash(tokenId))
					.on("error", (e) => catchError(tokenId, e))
					.then((receipt) => {
						parseResult(tokenId, receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				catchError(tokenId, e);
			}
		},
		enterBidForMartian: async ({
			tokenId,
			price,
			on,
			onError,
			onConfirmation,
		}) => {
			try {
				// const gasPrice = await getGasPrice();
				const options = {
					from: accounts.current,
					value: price,
					gasLimit: gasFees.enterBidForMartian,
				};
				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				await contracts.auction?.methods
					?.enterBidForMartian(tokenId)
					.send(options)
					.on("transactionHash", on)
					.on("error", (e) => {
						onError(e);
						catchError(tokenId, e);
					})
					.then((receipt) => {
						onConfirmation(receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				onError(e);
				catchError(tokenId, e);
			}
		},
		buyMartian: async (tokenId: number, value) => {
			try {
				emittingEvent(tokenId);
				// const gasPrice = await getGasPrice();
				const options = {
					from: accounts.current,
					value: web3js?.utils.toWei((value || 0) + "", "ether"),
					gasLimit: gasFees.buyMartian,
				};
				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				await contracts.auction?.methods
					.buyMartian(tokenId)
					.send(options)
					.on("transactionHash", () => onTransactionHash(tokenId))
					.on("error", (e) => {
						catchError(tokenId, e);
					})
					.then((receipt) => {
						parseResult(tokenId, receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				catchError(tokenId, e);
			}
		},
		withdrawBidForMartian: async ({ tokenId, onConfirmation, onError }) => {
			try {
				emittingEvent(tokenId);
				// const gasPrice = await getGasPrice();
				const options = {
					from: accounts.current,
					gasLimit: gasFees.withdrawBidForMartian,
				};
				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }
				await contracts.auction?.methods
					?.withdrawBidForMartian(tokenId)
					.send(options)
					.on("transactionHash", () => onTransactionHash(tokenId))
					.on("error", (e) => {
						onError();
						catchError(tokenId, e);
					})
					.then((receipt) => {
						onConfirmation();
						parseResult(tokenId, receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				onError(e);
				catchError(tokenId, e);
			}
		},
		addMartianToBattlefield: async ({ tokenId, onConfirmation, onError }) => {
			try {
				// const gasPrice = await getGasPrice();
				emittingEvent(tokenId);
				const options = {
					from: accounts.current,
					gasLimit: gasFees.addMartianToBattlefield,
				};

				console.log(options);

				// if (gasPrice && web3js?.utils) {
				// 	options["gasPrice"] = web3js?.utils.toWei(gasPrice / 10 + "", "gwei");
				// }

				console.log(tokenId);

				await contracts.base?.methods
					.safeTransferFrom(accounts.current, escrowEthAddress, tokenId)
					.send(options)
					.on("transactionHash", (hash) => {
						onTransactionHash(tokenId, hash);
					})
					.on("error", (e) => {
						onError();
						catchError(tokenId, e);
					})
					.then((receipt) => {
						onConfirmation();
						parseResult(tokenId, receipt);
						privateMethods.update(tokenId);
					});
			} catch (e) {
				console.log(e);
				catchError(tokenId, e);
			}
		},
		topupBattlefieldWithdrawBalance: async ({
			ethValue,
			onConfirmation,
			onError,
			onTransactionHashReady
		}) => {
			try {
				emittingEvent("sendingToEscrow");
				const options = {
					from: accounts.current,
					to: escrowEthAddress,
					value: web3js?.utils.toWei(ethValue),
				};

				console.log(options);
				await web3js?.eth
					.sendTransaction(options)
					.on("transactionHash", (hash) => {
						onTransactionHash("sendingToEscrow", hash)
						onTransactionHashReady(hash);
					})
					.on("error", (e) => {
						onError();
						catchError("sendingToEscrow", e);
					})
					.then((receipt) => {
						onConfirmation();
						parseResult("sendingToEscrow", receipt);
						privateMethods.update();
					});
			} catch (e) {
				catchError(null, e);
			}
		},
	};
	/** uses sha3 and sign to create a digital signature
	 * See more:
	 * - https://web3js.readthedocs.io/en/v1.2.11/web3-utils.html#sha3
	 * - https://web3js.readthedocs.io/en/v1.2.11/web3-utils.html#sha3
	 *
	 * @param address unlocked eth account
	 * @param email user email
	 * @returns string
	 */

	const createSignature = async (address: string, email: string) => {
		if (web3js) {
			const {
				utils: { sha3 },
				eth,
			} = web3js;
			// const message = await sha3(email);
			const signature = await eth.personal.sign(email, address);
			return signature;
		}
	};

	return {
		accounts,
		claim,
		loadModal,
		logout,
		tokenByIndex,
		tokenURI,
		withdraw,
		getTotalSupply,
		getLockedEth: privateMethods.lockedEth,
		balanceOf: privateMethods.balanceOf,
		getAccountHistory: privateMethods.getAccountHistory,
		createSignature,
		...writeAction,
	};

	async function loadModal(force: boolean): Promise<{
		web3js?: any;
		contracts?: { base: any; auction: any };
		accounts?: { current: any };
		error?: boolean;
	}> {
		const contracts = { base: null, auction: null };
		let accounts;
		let web3js;
		if (libs.current) {
			const { Web3Modal, Web3 } = libs.current;
			const web3 = new Web3Modal({
				network: "mainnet", // optional
				cacheProvider: true, // optional
				providerOptions: getProviderOptions(libs.current), // required
				theme: "dark",
			});
			if (force || web3?.cachedProvider) {
				const provider = await web3.connect().catch(() => ({ error: true })); // set provider
				if (provider.error) {
					log(LOG_ACTIONS.MARTIANS_ACCOUNT_ERROR);
					return {
						error: true,
					};
				}
				web3js = new Web3(provider); // create web3 instance
				accounts = await web3js.eth.getAccounts();

				dispatch({ type: types.web3.SET_WEB3JS, payload: web3js });

				dispatch({
					type: types.web3.SET_CURRENT_ACCOUNT,
					payload: accounts[0],
				});
			}
		}

		return {
			web3js,
			contracts,
			accounts: {
				current: accounts?.[0],
			},
		};
	}

	async function getTotalSupply() {
		const { result } = await get(PRICES);
		if (!result.error) {
			dispatch({ type: types.web3.SET_CLAIM_DETAILS, payload: result.stats });
		}
	}

	async function tokenByIndex(id: number) {
		try {
			const land = await contracts.base?.methods?.tokenByIndex(id).call();
			return land;
		} catch (e) {}
	}
	async function tokenURI(id: number) {
		try {
			const uri = await contracts.base?.methods?.tokenURI(id).call();
			return uri;
		} catch (e) {}
	}

	async function withdraw() {
		emittingEvent("withdrawing");
		try {
			await contracts.auction?.methods
				?.withdraw()
				.send({ from: accounts.current, gasLimit: gasFees.withdraw })
				.on("transactionHash", () => {
					onTransactionHash("withdrawing");
				})
				.on("error", (e) => catchError("withdrawing", e))
				.then((receipt) => parseResult("withdrawing", receipt));
			privateMethods.update();
		} catch (e) {
			catchError("withdrawing", e);
		}
	}

	async function getMartianByTokenId(id) {
		return await get(
			(GET_MARTIAN_ID + "?force=" + Math.random()).replace(":token_id", id)
		);
	}

	function logout() {
		if (libs.current) {
			const { Web3Modal } = libs.current;
			const web3 = new Web3Modal({
				network: "mainnet", // optional
				cacheProvider: true, // optional
				providerOptions: getProviderOptions(libs.current), // required
				theme: "dark",
			});
			web3?.clearCachedProvider();
			location.href = "/";
		}
	}
	function emittingEvent(id) {
		addToast("Confirm the transaction in your wallet", {
			appearance: "info",
			autoDismiss: false,
			loader: true,
			id,
		});
	}
	function onTransactionHash(id, hash = null) {
		removeToast(id, () => {
			setTimeout(() => {
				addToast("Adding it to the blockchain...", {
					appearance: "info",
					autoDismiss: false,
					loader: true,
					id,
				});

				if (hash) {
					setTimeout(() => {
						removeToast(id, () => {
							addToast(
								<a target="_blank" href={`https://etherscan.io/tx/${hash}`}>
									Click to check status on etherscan
								</a>,
								{
									appearance: "info",
									autoDismiss: false,
									loader: true,
									id: id
								}
							);
						});
					}, 1000);
				}
			}, 1000);
		});
	}
	function parseResult(id, receipt) {
		removeToast(id, () => {
			setTimeout(() => {
				if (receipt.status == "0x1" || receipt.status == 1) {
					addToast("Transaction Success!", {
						appearance: "success",
						autoDismiss: true,
					});
				} else {
					addToast("Transaction failed, please try again?", {
						appearance: "error",
						autoDismiss: true,
					});
				}
			}, 1000);
		});
	}
	function catchError(id, err) {
		removeToast(id, () => {
			setTimeout(() => {
				addToast(err.message, {
					appearance: "error",
					autoDismiss: true,
				});
			}, 300);
		});
	}
	async function loadAbis(web3js: any): Promise<any> {
		if (!web3js) {
			return;
		}
		return Promise.all(
			process.env.NEXT_PUBLIC_STAGE !== "staging"
				? [
						{
							name: "core",
							type: types.web3.SET_CONTRACT_BASE,
							value: await import("abis/MarsGenesisMartiansCore.json"),
						},
						{
							name: "auction",
							type: types.web3.SET_CONTRACT_AUNCTION,
							value: await import("abis/MarsGenesisMartiansAuction.json"),
						},
				  ]
				: [
						{
							name: "core",
							type: types.web3.SET_CONTRACT_BASE,
							value: await import("abis/staging/MarsGenesisMartiansCore.json"),
						},
						{
							name: "auction",
							type: types.web3.SET_CONTRACT_AUNCTION,
							value: await import(
								"abis/staging/MarsGenesisMartiansAuction.json"
							),
						},
				  ]
		).then(async (abis: any) => {
			const networkId = await web3js.eth.net.getId();
			abis.forEach((current) => {
				const networkData = current?.value?.networks[networkId];
				const address = networkData?.address;
				const contract = new web3js.eth.Contract(
					current?.value?.abi as any,
					address
				);
				if (contract) {
					dispatch({ type: current?.type, payload: contract });
					contracts[current?.name] = contract;
				}
			});
			return { contracts };
		});
	}

	async function getGasPrice() {
		const { result } = await get(
			"https://ethgasstation.info/json/ethgasAPI.json"
		);
		return result?.fast;
	}
}

function getProviderOptions(libs) {
	if (!libs) {
		return;
	}
	const { WalletConnectProvider, WalletLink } = libs;
	return {
		walletconnect: {
			package: WalletConnectProvider,
			options: {
				infuraId,
			},
		},
		"custom-walletlink": {
			display: {
				logo: "/static/images/wallets/coinbase-wallet.svg",
				name: "WalletLink",
				description: "Scan with WalletLink to connect",
			},
			options: {
				appName: "Mars Genesis", // Your app name
				networkUrl: `https://mainnet.infura.io/v3/${infuraId}`,
			},
			package: WalletLink,
			connector: async (_, options) => {
				const { appName, networkUrl, chainId } = options;
				const walletLink = new WalletLink({
					appName,
				});
				const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
				await provider.enable();
				return provider;
			},
		},
	};
}
