import { useEffect, useState } from "react";

export default function Burger({ onChange, state }) {
	const [active, setactive] = useState(false);

	useEffect(() => {
		onChange(active);
	}, [active]);

	useEffect(() => {
		setactive(state);
	}, [state]);

	return (
		<button
			onClick={() => setactive((active) => !active)}
			className={`inline-flex h-12 w-12 plate4 text-white focus:outline-none ${
				active ? "active" : ""
			}`}
		>
			<svg
				className="burger absolute w-12 h-12 text-white"
				version="1.1"
				height="100"
				width="100"
				viewBox="0 0 100 100"
			>
				<path stroke="currentColor" className="line line1" d="M 50,35 H 30" />
				<path stroke="currentColor" className="line line2" d="M 50,35 H 70" />
				<path stroke="currentColor" className="line line3" d="M 50,50 H 30" />
				<path stroke="currentColor" className="line line4" d="M 50,50 H 70" />
				<path stroke="currentColor" className="line line5" d="M 50,65 H 30" />
				<path stroke="currentColor" className="line line6" d="M 50,65 H 70" />
			</svg>
			<svg
				className="x absolute w-12 h-12"
				version="1.1"
				height="100"
				width="100"
				viewBox="0 0 100 100"
			>
				<path stroke="currentColor" className="line" d="M 34,32 L 66,68" />
				<path stroke="currentColor" className="line" d="M 66,32 L 34,68" />
			</svg>
			<style jsx>
				{`
					.x {
						transform: scale(0);
						transition: transform 400ms;
					}
					.line {
						fill: none;
						stroke-width: 6px;
						stroke-linecap: round;
						stroke-linejoin: round;
						transform-origin: 50%;
						transition: stroke-dasharray 500ms 200ms,
							stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
					}
					.x .line {
						stroke-width: 5.5px;
					}
					.plate4 .x {
						transition: transform 400ms;
					}
					.plate4 .line {
						transform-origin: 50%;
						transition: transform 400ms 100ms;
					}
					.active.plate4 .line {
						transition: transform 400ms;
					}
					.active.plate4 .line1 {
						transform: translateX(18px) translateY(-3px) rotate(-45deg)
							scale(0.7);
					}
					.active.plate4 .line2 {
						transform: translateX(-18px) translateY(-3px) rotate(45deg)
							scale(0.7);
					}
					.active.plate4 .line3 {
						transform: translateY(0px) rotate(45deg) scale(0.7);
					}
					.active.plate4 .line4 {
						transform: translateY(0px) rotate(-45deg) scale(0.7);
					}
					.active.plate4 .line5 {
						transform: translateX(18px) translateY(3px) rotate(45deg) scale(0.7);
					}
					.active.plate4 .line6 {
						transform: translateX(-18px) translateY(3px) rotate(-45deg)
							scale(0.7);
					}
					.active.plate4 .x {
						transition: transform 400ms 100ms;
						transform: scale(1);
					}
				`}
			</style>
		</button>
	);
}
