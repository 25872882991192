import { lazy, Suspense } from "react";

const Lottie = lazy(() => import("react-lottie-player"));
import lottieJson from "public/static/lotties/minting.json";

const snackStates = {
	entering: "scale-70 translate-y-full opacity-0",
	entered: "scale-100 translate-y-0 opacity-100",
	exiting: "scale-70 translate-y-full opacity-0",
	exited: "scale-70 translate-y-full opacity-0",
};

const appearances = {
	success: "text-green-500",
	error: "text-red-500",
	warning: "text-orange-500",
	info: "text-white",
};

export default function Snack({
	children,
	transitionState,
	onDismiss,
	loader,
	appearance,
}: any) {
	return (
		<div
			className={`relative items-center md:mb-2 justify-center transform bg-blueGray-900 rounded-t-lg md:rounded-lg text-white flex flex-wrap space-between p-4 transition-all z-50 w-screenWidth max-w-lg ${snackStates[transitionState]}
    `}
		>
			{loader ? (
				typeof window === "undefined" ? (
					<div style={{ width: 50, height: 50 }} />
				) : (
					<Suspense fallback={<div style={{ width: 50, height: 50 }} />}>
						<Lottie
							className="-ml-12"
							style={{ width: 50, height: 50 }}
							animationData={lottieJson}
							speed={1}
							background="#000000"
							loop
							play={true}
						/>
					</Suspense>
				)
			) : null}
			<p className={`text-center ${appearances[appearance]}`}>{children}</p>
			{onDismiss ? (
				<button
					className="hover:text-blackBlue absolute right-2 top-2 flex text-blueGray-300 md:text-2xl"
					onClick={onDismiss}
				>
					<svg
						className="w-6 h-6"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M6 18L18 6M6 6l12 12"
						/>
					</svg>
				</button>
			) : null}
		</div>
	);
}
