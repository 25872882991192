import { ToastProvider } from "react-toast-notifications";
import Head from "next/head";
import Script from "next/script";
import Header from "components/Header";
import Footer from "components/Footer";
import Snack from "components/Snack";
import StateProvider from "state";

const DefaultLayout = ({ Component, pageProps }) => {
	return (
		<>
			<StateProvider>
				<Head>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="/apple-touch-icon.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="32x32"
						href="/favicon-32x32.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href="/favicon-16x16.png"
					/>
					<link rel="manifest" href="/site.webmanifest" />
					<link
						href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800"
						rel="stylesheet"
					/>
				</Head>
				<Script
					id="model-viewer"
					src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
					strategy="lazyOnload"
					type="module"
				/>
				<ToastProvider placement="bottom-center" components={{ Toast: Snack }}>
					<div className="min-h-screen">
						<Header />
						<Component {...pageProps} />
						<Footer />
					</div>
				</ToastProvider>
			</StateProvider>
			<div id="Modal" />
		</>
	);
}

export default DefaultLayout; 