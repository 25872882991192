import { types } from "../index";

export const historyState = {
	stats: {
		average: 0,
		highest: 0,
		lowest: 0,
	},
	lastTransactions: [],
	largestSales: {
		entries: {},
		keys: [],
	},
	offered: {
		entries: {},
		keys: [],
	},
	lastBids: {
		entries: {},
		keys: [],
	},
	lastSales: {
		entries: {},
		keys: [],
	},
};

export type Martian = {
	id: number;
	head: { id: number; name: string; nb_used: number; nb_available: number };
	ear: { id: number; name: string; nb_used: number; nb_available: number };
	eye: {
		id: number;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	bid?: {
		address: string;
		has_bid: boolean;
		token_id: number;
		display: string;
		value: {
			eth: number;
			usd: string;
		};
	};
	history?: any[];
	mouth: { id: number; name: string; nb_used: number; nb_available: number };
	skin: { id: number; name: string; nb_used: number; nb_available: number };
	vest: {
		id: number;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	saucer: {
		id: number;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	currency: {
		id: number;
		symbol: string;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	is_golden: false;
	meta: {
		image_hash: string;
		glb_hash: string;
		metadata: { url: string; hash: string };
		token_id?: number;
		signature: string;
	};
	assets: {
		big: {
			png_url: string;
			face_url: string;
			profile_url: string;
			profile_face_url: string;
		};
		small: {
			png_url: string;
			face_url: string;
			profile_url: string;
			profile_face_url: string;
		};
		glb_url: string;
		package_url: string;
		opensea_url: string;
	};
	owner?: {
		address: string;
		display: string;
		is_for_sale: boolean;
		is_for_sale_for: { eth: number; usd: string };
		max_price: { eth: number; usd: string };
	};
};

type Event = {
	martian_id: number;
	event_type: "bid_entered" | "martian_offered" | "bought";
	timestamp: number;
	from_address: string;
	to_address: null;
	token_id: number;
	value_in_eth: string;
	price: {
		eth: number;
		usd: string;
	};
};

export type History = {
	stats: {
		average: number;
		highest: number;
		lowest: number;
	};
	lastTransactions: { martian: Martian; event: Event }[];
	largestSales: {
		entries: { [key: string]: { martian: Martian; event: Event } };
		keys: string[];
	};
	offered: {
		entries: { [key: string]: { martian: Martian; event: Event } };
		keys: string[];
	};
	lastBids: {
		entries: { [key: string]: { martian: Martian; event: Event } };
		keys: string[];
	};
	lastSales: {
		entries: { [key: string]: { martian: Martian; event: Event } };
		keys: string[];
	};
};

const lands = (
	state: History,
	{ type, payload }: { type: string; payload?: anyObj }
) => {
	switch (type) {
		case types.GET_STATS: {
			if (payload?.error) {
				return {
					...state,
				};
			}
			return {
				...state,
				stats: {
					average: payload?.average,
					highest: payload?.highest,
					lowest: payload?.lowest,
				},
			};
		}
		case types.GET_LAST_TRANSACTIONS: {
			if (!payload?.value?.length) {
				return { ...state };
			}
			return {
				...state,
				lastTransactions: payload.value,
			};
		}
		case types.GET_LARGEST_SALES: {
			if (!payload?.value?.length) {
				return { ...state };
			}
			return {
				...state,
				largestSales: {
					...state.largestSales,
					entries: {
						...state.largestSales.entries,
						...payload.value.reduce(
							(
								acc: { [key: string]: { martian: Martian; event: Event } },
								cur: { martian: Martian; event: Event }
							) => {
								acc[cur.martian.id] = cur;
								return acc;
							},
							{}
						),
					},
					keys: [
						...new Set([...payload.value.map(({ martian }) => martian.id)]),
					],
					offset: payload?.offset,
				},
			};
		}
		case types.GET_OFFERED_FOR_SALE: {
			if (!payload?.value?.length) {
				return { ...state };
			}
			return {
				...state,
				offered: {
					...state.offered,
					entries: {
						...state.offered.entries,
						...payload.value.reduce(
							(
								acc: { [key: string]: { martian: Martian; event: Event } },
								cur: { martian: Martian; event: Event }
							) => {
								acc[cur.martian.id] = cur;
								return acc;
							},
							{}
						),
					},
					keys: [
						...new Set([...payload.value.map(({ martian }) => martian.id)]),
					],
					offset: payload?.offset,
				},
			};
		}
		case types.GET_LAST_BIDS: {
			if (!payload?.value?.length) {
				return { ...state };
			}
			return {
				...state,
				lastBids: {
					...state.lastBids,
					entries: {
						...state.lastBids.entries,
						...payload.value.reduce(
							(
								acc: { [key: string]: { martian: Martian; event: Event } },
								cur: { martian: Martian; event: Event }
							) => {
								acc[cur.martian.id] = cur;
								return acc;
							},
							{}
						),
					},
					keys: [
						...new Set([...payload.value.map(({ martian }) => martian.id)]),
					],
					offset: payload?.offset,
				},
			};
		}
		case types.GET_LAST_SALES: {
			if (!payload?.value?.length) {
				return { ...state };
			}
			return {
				...state,
				lastSales: {
					...state.lastSales,
					entries: {
						...state.lastSales.entries,
						...payload.value.reduce(
							(
								acc: { [key: string]: { martian: Martian; event: Event } },
								cur: { martian: Martian; event: Event }
							) => {
								acc[cur.martian.id] = cur;
								return acc;
							},
							{}
						),
					},
					keys: [
						...new Set([...payload.value.map(({ martian }) => martian.id)]),
					],
					offset: payload?.offset,
				},
			};
		}
		default:
			return state;
	}
};

export default lands;
