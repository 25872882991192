import { AddMartianStep, BattleStep, SignupStep, types, UserType } from 'state';
import { get, post } from 'utils/fetch';

/**
 * This contract describes the three different cases for the application flow
 * "signup" triggers user partial changes over time, wallet => username => email => verify
 * "battlefield" TODO: placeholder
 * "add_martian" TODO: placeholder
 */
export type BattleStepType = "signup" | "battlefield" | "add_martian";

// API to Web data contract / Backend response model
type BattleInfoResponse = {
  status: string;
  result: {
    info?: {
      address: string;
      nickname: string | null;
      step: BattleStepType |AddMartianStep | BattleStep
    },
    error?: any
  }
}

// Partial updates for the user, initialized as defined user with empty properties
export const updateUserAction = (user: Partial<UserType>) => ({
  type: types.UPDATE_USER,
  payaload: user
});

export const getNextStepPayload = (user: BattleInfoResponse) => {
  switch(user.result.info?.step) {
    case 'signup': {
      return ({
        nextStep: SignupStep.set_username,
        background: '1'
      })
    }
    case 'add_martian': {
      return ({
        nextStep: AddMartianStep.add_martians,
        background: '2'
      })
    }
    case 'battlefield': {
      return ({
        nextStep: BattleStep.send_martians_to_battlefield,
        background: '2'
      })
    }

  }
}


// Fetch wallet information and stores it at User state model, used as first step before fetch username
export const getBattleInfo = async (address: string) => {
  const response: BattleInfoResponse = await get('/v1/battle/info', { address });

  return {
    type: types.UPDATE_USER,
    payload: {
      address,
      nickname: response.result.info?.nickname,
      step: response.result.info?.step, // current step flow
      ...getNextStepPayload(response),
    }
  }
}

// Fetch username for backedn, used as second step before fetch email
export const addUserName = async (address: string, username: string) => {
  const response = await post('/v1/battle/username', { address, username });

  if (!response.status || response.status !== 'ok') {
    throw new Error(response.result.error);
  }

  return {
    type: types.UPDATE_USER,
    payload: {
      address,
      nickname: username,
      nextStep: SignupStep.set_email
    }
  }
}

// Send signature to backend, used before email verification
export const sendEmail = async (ownerAddress: string, email: string, nickname: string, signature: string) => {
  const response = await get(
    '/verifyPersonalSignature', 
    {
      email,
      nickname,
      ownerAddress,
      signature
    },
    undefined,
    { isNodeJSQuery: true } // Call different backend endpoint
  );

  if (!response.status || response.status !== 'ok') {
    throw new Error('Could not save email');
  }

  return { 
    type: types.UPDATE_USER,
    payload: {
      nickname,
      email,
      address: ownerAddress,
      nextStep: SignupStep.wait_email_confirmation
    } 
  }
}

export const requestLogin = async (address: string) => {
  const response = await post('/v1/battle/request/login', { address }, undefined);

  if (!response.status || response.status !== 'ok') {
    throw new Error(response.result.error);
  }

  return {
    type: types.REQUESTED_LOGIN,
    payload: {}
  }
}

// Handshake signature with backend, used as final signup flow step before add_martian
export const verifyEmail = async (hash: string) => {
  const response = await post(`/v1/battle/verify/email/${hash}`);

  if (!response.result || !response.result.token) {
    // @TODO handle verify error
    throw new Error('Verification failed');
  }

  return {
    type: types.UPDATE_USER,
    payload: {
      email: response.result.email,
      nickname: response.result.nickname,
      isVerified: response.result.verified,
      address: response.result.address,
      step: response.result.step,
      nextStep: response.result.step,
      background: '3',
      token: response.result.token,
    }
  }
}
