import { types } from "../index";
import { Martian } from "state/reducers/lands";

export type Web3Account = {
	ref?: {
		eth: number;
		last_paid_at: number;
		mints: number;
		ref_url: string;
	};
	history?: any[];
	lockedEth?: string;
	balance?: {
		total: number;
		lands: {
			[key: string]: Martian;
		};
	};
};

export type Web3 = {
	eth_price: number;
	claim: {
		price: number;
		minted: number;
		leftCurrentPrice: number;
		progress: number;
		parcels_minted_before: number;
		total: number;
		golden_remaining: number;
	};
	contracts: {
		base?: anyObj;
		auction?: anyObj;
	};
	accounts: {
		entries: {
			[key: string]: Web3Account
		};
		current?: string;
	};
	web3js: any;
};
export const web3State = {
	eth_price: 0,
	claim: {
		price: 0,
		minted: 0,
		leftCurrentPrice: 0,
		progress: 0,
		parcels_minted_before: 0,
		total: 0,
		golden_remaining: 0,
	},
	contracts: {
		base: undefined,
		auction: undefined,
	},
	accounts: {
		entries: {},
		current: undefined,
	},
	web3js: null,
};

const web3 = (
	state: Web3,
	{ type, payload }: { type: string; payload?: any }
) => {
	switch (type) {
		case types.web3.GET_ETH_PRICE:
			if (!state.accounts.current) {
				state = {
					...state,
				};
			}
			state = {
				...state,
				eth_price: payload.value,
			};
			break;
		case types.web3.UPDATE_LOCKED_ETH:
			if (!state.accounts.current) {
				state = {
					...state,
				};
			}
			state = {
				...state,
				accounts: {
					...state.accounts,
					entries: {
						...state.accounts.entries,
						[state.accounts.current + ""]: {
							...state.accounts.entries[state.accounts.current + ""],
							lockedEth: payload,
						},
					},
				},
			};
			break;
		case types.web3.SET_CLAIM_DETAILS:
			state = {
				...state,
				claim: {
					price: payload?.price,
					minted: payload?.minted,
					leftCurrentPrice: payload?.leftCurrentPrice,
					progress: payload?.progress,
					parcels_minted_before: payload?.parcels_minted_before,
					total: payload?.total,
					golden_remaining: payload?.golden_remaining,
				},
			};
			break;
		case types.web3.SET_CURRENT_ACCOUNT:
			state = {
				...state,
				accounts: {
					...state.accounts,
					entries: {
						...state.accounts.entries,
						[payload]: {
							...state.accounts.entries[payload],
							history: state.accounts.entries[payload]?.history || [],
						},
					},
					current: payload,
				},
			};
			break;
		case types.web3.UPDATE_ACCOUNT_HISTORY:
			const current = payload.id;
			state = {
				...state,
				accounts: {
					...state.accounts,
					entries: {
						...state.accounts.entries,
						[current]: {
							...state.accounts.entries[current],
							history: payload.value,
						},
					},
				},
			};
			break;
		case types.web3.SET_CONTRACT_BASE:
			state = {
				...state,
				contracts: {
					...state.contracts,
					base: payload,
				},
			};
			break;
		case types.web3.SET_CONTRACT_AUNCTION:
			state = {
				...state,
				contracts: {
					...state.contracts,
					auction: payload,
				},
			};
			break;
		case types.web3.SET_WEB3JS:
			state = { ...state, web3js: payload };
			break;
		case types.web3.SET_ACCOUNT_BALANCE:
			state = {
				...state,
				accounts: {
					...state.accounts,
					entries: {
						...state.accounts.entries,
						[payload.id]: {
							...state.accounts.entries[payload.id],
							ref: payload.value.ref,
							balance: {
								total: payload.value.total || 0,
								lands: payload.value.lands?.map((card) => {
									return {
										...card,
										owner: {
											...card.owner,
											display: card?.owner?.is_for_sale
												? `${card?.owner?.is_for_sale_for?.eth}Ξ (${card?.owner?.is_for_sale_for.usd})`
												: "",
										},
										bid: {
											...(card?.bid || {}),
											display: card?.bid?.has_bid
												? `${card.bid.value.eth}Ξ (${card.bid.value.usd})`
												: "",
										},
									};
								}),
							},
						},
					},
				},
			};
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default web3;
