import '../public/static/style.css';

import DefaultLayout from 'components/Layouts/DefaultLayout';
import Router from 'next/router';
import { useEffect } from 'react';
import { saveQueryRefToLocalStorage } from 'utils';

function MyApp({ Component, pageProps }) {
	useEffect(() => {
		const routeChangeComplete = (url) => {
			saveQueryRefToLocalStorage(url);
		};

		Router.events.on("routeChangeComplete", routeChangeComplete);
		return () => {
			Router.events.off("routeChangeComplete", routeChangeComplete);
		};
	}, []);

	if (Component.getCustomLayout) {
		return Component.getCustomLayout(Component, pageProps);
	}

	return <DefaultLayout Component={Component} pageProps={pageProps} />;
}

export default MyApp;
