import parseHtml from 'html-react-parser';
import qs from 'qs';
import { LOG } from 'utils/endpoints';
import { post } from 'utils/fetch';

function chr4() {
	return Math.random().toString(16).slice(-4);
}
export function getRandomId() {
	return (
		chr4() +
		chr4() +
		"-" +
		chr4() +
		"-" +
		chr4() +
		"-" +
		chr4() +
		"-" +
		chr4() +
		chr4() +
		chr4()
	);
}

export function log(
	p: string,
	s?: string,
	r?: string,
	params?: { owner_address?: string; mint_error?: string }
) {
	const session_id = s || localStorage.getItem("rid");
	const ref = r || localStorage.getItem("ref");
	let page = p;
	if (/\/martian\/[0-9]*/g.test(p)) {
		page = "/martian/:id";
	}
	if (ref) {
		post(LOG, {
			page,
			session_id,
			ref,
			...(params || {}),
		});
	}
}

export function parse(str) {
	try {
		return parseHtml(str);
	} catch (e) {
		return str;
	}
}

export const saveQueryRefToLocalStorage = (url: String) => {
	try {
		const queries = qs.parse(window.location.search, {
			ignoreQueryPrefix: true,
		});
		console.log(queries);
		const ref = queries?.ref || localStorage.getItem("ref");
		const rf = queries?.rf || localStorage.getItem("rf");
		const session_id = localStorage.getItem("rid") || getRandomId();
		localStorage.setItem("rid", session_id);
		if (ref) {
			localStorage.setItem("ref", ref + "");
			log(url.split("?")[0], session_id, ref);
		}
		if (rf) {
			localStorage.setItem("rf", rf + "");
		}
	} catch (e) {
		console.log({ e });
	}
};

export const toQueryString = (obj) => {
	return Object.keys(obj)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
		.join("&");
};

export const getStoredBattlefieldUserToken = () => {
	return localStorage.getItem("battlefield_token") || undefined;
};

export const setStoredBattlefieldUserToken = (token) => {
	localStorage.setItem("battlefield_token", token);
};

export const replaceAssetsUrl = (url) => {
	if (!url) {
		return url;
	}
	// return url.replace("assets.marsgenesis.com", "battleofmars.com/s3");
	return url;
};

export const splitChunks = (inputArray, perChunk) => {
	return inputArray.reduce((all, one, i) => {
		const ch = Math.floor(i / perChunk);
		all[ch] = [].concat(all[ch] || [], one);
		return all;
	}, []);
};

export const LOG_ACTIONS = {
	MARTIANS_MINT_CLICKED: "MARTIANS_MINT_CLICKED",
	MARTIANS_ASK_ACCOUNT: "MARTIANS_ASK_ACCOUNT",
	MARTIANS_MINT_STARTED: "MARTIANS_MINT_STARTED",
	MARTIANS_MINT_SUCCESS: "MARTIANS_MINT_SUCCESS",
	MARTIANS_MINT_ERROR: "MARTIANS_MINT_ERROR",
	MARTIANS_ACCOUNT_CONNECTED: "MARTIANS_ACCOUNT_CONNECTED",
	MARTIANS_ACCOUNT_ERROR: "MARTIANS_ACCOUNT_ERROR",
};

export const TAGS = [
	{
		tag: "currency",
		prop: "symbol",
		color:
			"text-amber-300 hover:text-black hover:border-amber-300 hover:bg-amber-300",
	},
	{
		tag: "mouth",
		prop: "name",
		color:
			"text-emerald-300 hover:text-black hover:border-emerald-300 hover:bg-emerald-300",
	},
	{
		tag: "saucer",
		prop: "name",
		color:
			"text-pink-400 hover:text-black hover:border-pink-400 hover:bg-pink-400",
	},
	{
		tag: "eye",
		prop: "name",
		color:
			"text-amber-500 hover:text-black hover:border-amber-500 hover:bg-amber-500",
	},
	{
		tag: "head",
		prop: "name",
		color:
			"text-amber-100 hover:text-black hover:border-amber-100 hover:bg-amber-100",
	},
	{
		tag: "skin",
		prop: "name",
		color:
			"text-blue-300 hover:text-black hover:border-blue-300 hover:bg-blue-300",
	},
	{
		tag: "ear",
		prop: "name",
		color:
			"text-red-200 hover:text-black hover:border-red-200 hover:bg-red-200",
	},
	{
		tag: "vest",
		prop: "name",
		color: "text-white hover:text-black hover:border-white hover:bg-white",
	},
];
