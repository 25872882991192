import landsReducer, { landsState, Lands } from "state/reducers/lands";
import historyReducer, { historyState, History } from "state/reducers/history";
import web3Reducer, { web3State, Web3 } from "state/reducers/web3";
import userReducer, { userState, UserType } from "./user";
import battlefieldReducer from './battlefield';
import { BattlefieldType, battlefieldState } from './battlefield';

export interface StateInterface {
	lands: Lands;
	history: History;
	web3: Web3;
  user: UserType;
	battlefield: BattlefieldType;
}

export default function mainReducer(
	{ lands, history, web3, user, battlefield }: StateInterface,
	action: { type: string; payload: any }
): StateInterface {
	// middleware goes here, i.e calling analytics service, etc.
	return {
		lands: landsReducer(lands, action),
		history: historyReducer(history, action),
		web3: web3Reducer(web3, action),
    user: userReducer(user, action),
		battlefield: battlefieldReducer(battlefield, action),
	};
}

export const initialState = {
	lands: landsState,
	history: historyState,
	web3: web3State,
  user: userState,
	battlefield: battlefieldState
};

export * from './history';
export * from './user';
export * from './web3';
export * from './battlefield';