import { get, post } from "./fetch";

export const LOG = "/v1/log";
export const WALLETS = "/v1/wallets";
export const EMAIL = "/v1/account/email/:address";
export const EMAIL_VERIFY = "/v1/verify/email/:hash";

export const GET_MARTIAN_ID = "/v1/martian/token/:token_id";
export const MARTIAN = "/v1/martian/:id";
export const REDEEM = "/v1/martian/redeem";
export const REDEEM_INFO = "/v1/martian/redeem/info";
export const PRICES = "/v1/martian/mint/stats";
export const MINT = "/v1/martian/mint";
export const SEARCH = "/v1/martian/discover"
export const LAST_TRANSACTIONS = "/v1/martian/transactions/last"
export const OFFERED_FOR_SALE = "/v1/martian/offered/all";
export const LAST_BIDS = "/v1/martian/bids/last"
export const LARGEST_SALES = "/v1/martian/sales/largest";
export const LAST_SALES = "/v1/martian/sales/last";
export const STATS = "/v1/martian/trades/stats"
export const BALANCE_OF = "/v1/account/martians/:id";
export const HISTORY = "/v1/account/martians/history/:id";


// API Routes for battle
export const BATTLE_ENDPOINTS = Object.freeze({
  signup_get_info_step: "",
  signup_add_username_step: "",
  verify_signature: "/verifySignature",
  verify_battle_email: "/v1/battle/verify/email"
});


// Web to API data contract
type VerifySignatureRequest = {
  email: string;
  username: string;
  address: string;
  signature: string;
  token: string
}
// API to Web data contract
type VerifySignatureResponse = {
  status: string;
}
// Endpoint contract definition
export type VerifySignatureType = (request: VerifySignatureRequest) => Promise<VerifySignatureResponse>;
// Endpoint implementation
export const VerifySignatureEndpoint: VerifySignatureType = async (request) => 
  get(BATTLE_ENDPOINTS.verify_signature, request, request.token, );


type VerifyEmailBattleRequest = {
  hash: string
}

type VerifyEmailBattleResponse = {
  status: string;
  result: {
    email: string;
    nickname: string;
    address: string;
    verified: boolean
  } | any
}

export type VerifyEmailBattleType = (request: VerifyEmailBattleRequest) => Promise<VerifyEmailBattleResponse>

export const VerifyEmailBattleEndpoint: VerifyEmailBattleType = async ({hash}) => 
  post(`${BATTLE_ENDPOINTS.verify_battle_email}/${hash}`)