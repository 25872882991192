import { forwardRef } from "react";

function Logo(
	{
		type = "mrtn",
		component = "a",
		size = ["w-8", "w-36"],
		className,
		...props
	}: any,
	ref: any
) {
	const View = component;
	const isMartian = type === "mrtn";
	const srcs = isMartian
		? {
				logo: "/static/images/logo/mrtn_logo.png",
				text: "/static/images/logo/mrtn_logo_text.png",
				label: "The Martians",
		  }
		: {
				logo: "/static/images/logo/mg_logo.png",
				text: "/static/images/logo/mg_logo_text.png",
				label: "Mars Genesis",
		  };
	return (
		<View
			ref={ref}
			{...props}
			className={`flex items-center transform space-x-1 ${className || ""}`}
		>
			<img className={size[0]} src={srcs.logo} alt="" />
			<img className={size[1]} src={srcs.text} alt="" />
			<span className="sr-only">{srcs.label}</span>
		</View>
	);
}

export default forwardRef(Logo);
