import Link from "next/link";

export default function Footer() {
	return (
		<footer className="sticky z-50 top-full flex items-center pb-2 pt-24 max-w-full text-blueGray-50 font-bold overflow-auto">
			<div className="items 2xl:container flex 2xl:mx-auto px-7 w-full">
				<p className="flex items-center justify-center whitespace-nowrap">
					©Mars Genesis
				</p>
				<div className="flex justify-center ml-auto pl-7 pr-20 2xl:px-0 space-x-4 md:px-16">
					<LinkItem
						target="_blank"
						rel="noopener noreferer"
						href="https://marsgenesis.com/about"
					>
						About
					</LinkItem>
					<LinkItem
						target="_blank"
						rel="noopener noreferer"
						href="https://marsgenesis.com/project"
					>
						Project
					</LinkItem>
					<LinkItem
						target="_blank"
						rel="noopener noreferer"
						href="https://marsgenesis.com/press"
					>
						Press
					</LinkItem>
					<LinkItem
						target="_blank"
						rel="noopener noreferer"
						href="https://marsgenesis.com/terms"
					>
						Terms
					</LinkItem>
					<LinkItem
						href="https://discord.gg/martians"
						target="_blank"
						rel="noopener noreferer"
					>
						Discord
					</LinkItem>
					<LinkItem
						href="https://twitter.com/3dmartians"
						target="_blank"
						rel="noopener noreferer"
					>
						Twitter
					</LinkItem>
				</div>
			</div>
		</footer>
	);
}

const LinkItem = ({ children, href, ...props }) => {
	return (
		<>
			<Link href={href}>
				<a
					className="inline-flex items-center px-2 py-6 text-blueGray-100 hover:text-blueGray-50 hover:underline focus:underline tracking-wide outline-none"
					href={href}
					{...props}
				>
					{children}
				</a>
			</Link>
		</>
	);
};
