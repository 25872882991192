import { types } from "state";
import { get } from "utils/fetch";
import { MARTIAN } from "utils/endpoints";

export async function getMartianById(id: number) {
	const { result } = await get(MARTIAN.replace(":id", id + ""));
	return {
		type: types.GET_DATA,
		payload: { id, value: { ...(result.martian || {}) } },
	};
}
