import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import mainReducer, { initialState, StateInterface } from 'state/reducers';

export const StateContext =
	createContext<
		[StateInterface, Dispatch<{ type: string; payload: any }>] | null
	>(null);

export default function StateProvider({
	children,
	initialValue,
}: {
	initialValue?: { [key: string]: any };
	children: React.ReactNode;
}) {
	const init = {
		...initialState,
		...(initialValue || {}),
	};

	return (
		<StateContext.Provider value={useReducer(mainReducer, init)}>
			{children}
		</StateContext.Provider>
	);
}

export const useGlobalState = () =>
	useContext(StateContext) as [
		StateInterface,
		Dispatch<{ type: string; payload: any }>
	];
export const types = {
	UPDATE_USER: "UPDATE_USER",
	UPDATE_WEB3: "UPDATE_WEB3",
	REQUESTED_LOGIN: "REQUESTED_LOGIN",
	GET_DATA: "GET_DATA",
	GET_LAST_TRANSACTIONS: "GET_LAST_TRANSACTIONS",
	GET_OFFERED_FOR_SALE: "GET_OFFERED_FOR_SALE",
	GET_LAST_BIDS: "GET_LAST_BIDS",
	GET_LARGEST_SALES: "GET_LARGEST_SALES",
	GET_LAST_SALES: "GET_LAST_SALES",
	GET_STATS: "GET_STATS",
	battlefield: {
		SET_MARTIANS_IN_TRENCHES: "SET_MARTIANS_IN_TRENCHES",
		SET_MARTIANS_IN_BATTLEFIELD: "SET_MARTIANS_IN_BATTLEFIELD",
		SET_BATTLE_HISTORY: "SET_BATTLE_HISTORY",
		SET_BATTLE_PROFILE: "SET_BATTLE_PROFILE",
		SET_CURRENT_BATTLE: "SET_CURRENT_BATTLE",
		REGISTER_RANDOM_BATTLE_WAIT: "REGISTER_RANDOM_BATTLE_WAIT",
		REGISTER_RANDOM_BATTLE_FOUND: "REGISTER_RANDOM_BATTLE_FOUND",
		REGISTER_RANDOM_BATTLE_CANCEL: "REGISTER_RANDOM_BATTLE_CANCEL",
		MARTIAN_WITHDRAWN: "MARTIAN_WITHDRAWN",
		CHALLENGE_SENT: "CHALLENGE_SENT",
		CHALLENGE_ACCEPTED: "CHALLENGE_ACCEPTED"
	},
	web3: {
		SET_CONTRACT_BASE: "SET_CONTRACT_BASE",
		SET_CONTRACT_AUNCTION: "SET_CONTRACT_AUNCTION",
		SET_CLAIM_DETAILS: "SET_CLAIM_DETAILS",
		SET_ACCOUNT_BALANCE: "SET_ACCOUNT_BALANCE",
		SET_CURRENT_ACCOUNT: "SET_CURRENT_ACCOUNT",
		UPDATE_LOCKED_ETH: "UPDATE_LOCKED_ETH",
		UPDATE_ACCOUNT_HISTORY: "UPDATE_ACCOUNT_HISTORY",
		UPDATE_LAND: "UPDATE_LAND",
		SET_WEB3JS: "SET_WEB3JS",
		GET_ETH_PRICE: "GET_ETH_PRICE",
	},
};

export * from './actions';
export * from './reducers';
