import { types } from 'state';

export const landsState = {
	entries: {},
};

export type Martian = {
	id: number;
	head: { id: number; name: string; nb_used: number; nb_available: number };
	ear: { id: number; name: string; nb_used: number; nb_available: number };
	eye: {
		id: number;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	bid?: {
		address: string;
		has_bid: boolean;
		token_id: number;
		display: string;
		value: {
			eth: number;
			usd: string;
		};
	};
	history?: any[];
	mouth: { id: number; name: string; nb_used: number; nb_available: number };
	skin: { id: number; name: string; nb_used: number; nb_available: number };
	vest: {
		id: number;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	rarity: {
		percentage_rarity: number;
		match_min_with: number;
		percentile: number;
	};
	saucer: {
		id: number;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	currency: {
		id: number;
		symbol: string;
		name: string;
		nb_used: number;
		nb_available: number;
	};
	is_golden: false;
	meta: {
		image_hash: string;
		glb_hash: string;
		metadata: { url: string; hash: string };
		token_id?: number;
		signature: string;
	};
	assets: {
		big: {
			png_url: string;
			face_url: string;
			profile_url: string;
			profile_face_url: string;
		};
		small: {
			png_url: string;
			face_url: string;
			profile_url: string;
			profile_face_url: string;
		};
		glb_url: string;
		package_url: string;
		opensea_url: string;
	};
	owner?: {
		address: string;
		display: string;
		is_for_sale: boolean;
		is_for_sale_for: { eth: number; usd: string };
		max_price: { eth: number; usd: string };
	};
};

export type Lands = {
	entries: { [key: string]: Martian };
};

const lands = (
	state: Lands,
	{ type, payload }: { type: string; payload?: anyObj }
) => {
	switch (type) {
		case types.GET_DATA: {
			const current = payload?.value || {};
			return {
				...state,
				entries: {
					...state.entries,
					[payload?.id]: {
						...(state.entries?.[payload?.id] || {}),
						...current,
						owner: {
							...current.owner,
							display: current.owner?.is_for_sale
								? `${current?.owner?.is_for_sale_for?.eth}Ξ (${current?.owner?.is_for_sale_for?.usd})`
								: "",
						},
						bid: {
							...(current?.bid || {}),
							display: current?.bid?.has_bid
								? `${current?.bid?.value?.eth}Ξ (${current?.bid?.value?.usd})`
								: "",
						},
					},
				},
			};
		}
		default:
			return state;
	}
};

export default lands;
